import { TbRefresh } from "solid-icons/tb";
import {
  type Component,
  createEffect,
  createMemo,
  ErrorBoundary,
  For,
  Match,
  on,
  onCleanup,
  onMount,
  Show,
  startTransition,
  Suspense,
  Switch,
} from "solid-js";
import { SecondaryCTA } from "@core/components/cta/SecondaryCTA";
import { Loading } from "@core/components/Loading";
import { SidebarCollection } from "@core/components/SideBarCollection";
import { CollectionKind } from "@core/domains/collections/collections.types";
import { useWire } from "@core/wire";
import styles from "./SidebarCollections.module.css";
import { useUIState } from "@core/ui/UIState";
import { CustomEvents } from "@core/ui/custom-events";

export const SidebarCollections: Component<{
  /**
   * Callback for when a collection is clicked.
   * The default behavior is to navigate to the collection url.
   * Passing a callback will override the default behavior.
   */
  onCollectionClick?: (collectionId: string) => void;
  filter?: string;
}> = (props) => {
  const wire = useWire();
  const collections = wire.services.collections;
  const [tree, { refetch: refetchTree }] = collections.globalResourceTree;

  const ui = useUIState();
  const [collectionId] = ui.collectionId;

  const roots = () => (!collections.getRootsLoaded() ? [] : collections.getRootCollections());

  onMount(() => {
    const listener = (e: CustomEvent<{ collectionId: string }>) => {
      const id = e.detail.collectionId;

      const el = document.querySelector(
        `button[data-component="sidebar-accordion-trigger"][data-collection-id="${id}"]`,
      );

      const leftBar = document.querySelector("[data-component='sidebar-collections']");
      if (!leftBar || !el) return;

      const barRect = leftBar.getBoundingClientRect();
      let { top } = el.getBoundingClientRect();
      top = Math.round(top);
      const scroll = Math.round(leftBar.scrollTop);

      const newScroll = scroll + top - barRect.top;

      // Check if element is already in view before scrolling
      const leftBarHeight = leftBar.clientHeight;
      const elementHeight = el.clientHeight;
      const elementTop = top - scroll - barRect.top;
      const elementBottom = elementTop + elementHeight;

      // Element is already fully visible, no need to scroll
      if (elementTop >= 0 && elementBottom <= leftBarHeight) {
        return;
      }
      leftBar.scrollTo({ top: newScroll, behavior: "smooth" });
    };
    CustomEvents.scrollCollectionIntoView.add(listener);
    onCleanup(() => {
      CustomEvents.scrollCollectionIntoView.remove(listener);
    });
  });

  const memoId = createMemo(() => collectionId());
  createEffect(
    on(memoId, (id) => {
      CustomEvents.scrollCollectionIntoView.dispatch({ collectionId: id });
    }),
  );

  return (
    <Suspense fallback={<Loading />}>
      <ErrorBoundary
        fallback={(_err, reset) => (
          <div class={styles["sidebar-collections__error-boundary"]}>
            <p class={styles["sidebar-collections__error-boundary-text"]}>
              There was an error loading your Collections.
            </p>
            <div>
              <SecondaryCTA
                size="small"
                data-test-id="sidebar-collections-error-boundary"
                accessiblePrefix="Click to "
                icon={TbRefresh}
                label="Reload"
                onClick={() => {
                  reset();
                  startTransition(() => refetchTree());
                }}
              />
            </div>
          </div>
        )}
      >
        <Show when={collections.getRootsLoaded() && tree()} fallback={<Loading />}>
          {/* <SideBarSectionAccordion label="Collections" initiallyOpen depth={0}> */}
          <For each={roots()}>
            {(root) => (
              <Switch>
                <Match when={root.collectionKind !== CollectionKind.Favorites}>
                  <SidebarCollection
                    filter={props.filter}
                    onCollectionClick={props.onCollectionClick}
                    onCollectionCreated={refetchTree}
                    root
                    collectionId={root.id}
                    depth={0}
                    refreshTree={refetchTree}
                  />
                </Match>
                <Match when={collections.getChildrenIds(root.id).length === 0}>{null}</Match>
                <Match when={true}>
                  <For each={collections.getChildrenIds(root.id)}>
                    {(child) => (
                      <SidebarCollection
                        filter={props.filter}
                        onCollectionClick={props.onCollectionClick}
                        onCollectionCreated={refetchTree}
                        collectionId={child}
                        depth={0}
                        refreshTree={refetchTree}
                      />
                    )}
                  </For>
                </Match>
              </Switch>
            )}
          </For>
          {/* </SideBarSectionAccordion> */}
        </Show>
      </ErrorBoundary>
    </Suspense>
  );
};

// const CreateSharedCollection: Component<{ sharedRootId: string; hasShared: boolean }> = (props) => {
//   const wire = useWire();
//   const state = useUIState();
//   const [, setSidebarOpen] = state.leftDrawer;
//   const [, setModalOpen] = state.modal;
//   const [, setModalContents] = state.modalContents;
//   const [, setOpenShareDrawer] = state.openShareDrawer;
//   const navigate = useNavigate();

//   return (
//     <PrimaryCTA
//       data-test-id="sidebar-start-collaborating"
//       icon={TbUsersGroup}
//       accessiblePrefix="Click to "
//       label={props.hasShared ? "New shared Collection" : "Start collaborating"}
//       size={props.hasShared ? "small" : "medium"}
//       class="mt-3"
//       onClick={() => {
//         setModalContents(() => () => (
//           <CreateSharedCollectionModal
//             id="create-shared-collection-modal"
//             onCreate={async (name, description) => {
//               const res = await wire.services.collections.apiCreateCollection(
//                 wire.services.collections.getDataOrFail(props.sharedRootId),
//                 name,
//                 description,
//               );
//               setSidebarOpen(false);
//               setOpenShareDrawer(true);
//               navigate(urls.collection(res.data.id));

//               setModalOpen("");
//               setModalContents(null);
//             }}
//           />
//         ));
//         setModalOpen("create-shared-collection-modal");
//       }}
//     />
//   );
// };
