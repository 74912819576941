import type { IconTypes } from "solid-icons";
import { Show, splitProps } from "solid-js";
import { StIcon } from "@core/components/icons";
import { classNames } from "@core/lib/classNames";
import styles from "./SecondaryCTAAnchor.module.css";
import type { CTAProps } from "./types";

interface ButtonProps extends CTAProps {
  label: string;
  icon?: IconTypes;
  rounded?: boolean;
  accessiblePrefix?: string;
  accessibleSuffix?: string;
  inactive?: boolean;
  href: string;
}
type Props = RequireAtLeastOne<ButtonProps, "accessiblePrefix" | "accessibleSuffix">;

export const SecondaryCTAAnchor = (props: Props) => {
  const [local, rest] = splitProps(props, [
    "label",
    "accessiblePrefix",
    "accessibleSuffix",
    "icon",
    "class",
    "inactive",
    "rounded",
    "href",
  ]);
  return (
    <a
      {...rest}
      class={classNames(
        styles["secondary-cta-anchor"],
        local.rounded ? styles["secondary-cta-anchor--rounded"] : "",
        local.inactive ? styles["secondary-cta-anchor--inactive"] : "",
        local.class,
      )}
      tabIndex={0}
      href={local.href}
    >
      <Show when={local.icon}>
        <StIcon icon={local.icon!} class={styles["secondary-cta-anchor__icon"]} size="1.25rem" />
      </Show>
      <Show when={local.accessiblePrefix}>
        <span class="screen-reader">{local.accessiblePrefix}</span>
      </Show>
      <span class={styles["secondary-cta-anchor__label"]}>{local.label}</span>
      <Show when={local.accessibleSuffix}>
        <span class="screen-reader">{local.accessibleSuffix}</span>
      </Show>
    </a>
  );
};
