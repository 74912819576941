import { stAnalytics } from "@repo/analytics";
import { TbDotsVertical } from "solid-icons/tb";
import { Match, type ParentProps, Show, Switch, createSignal, createUniqueId } from "solid-js";
import { StIcon, type StIconComponent } from "@core/components/icons";
import { CollectionKind } from "@core/domains/collections/collections.types";
import { classNames } from "@core/lib/classNames";
import { getScreenType } from "@core/lib/ui/getScreenType";
import { useUIState } from "@core/ui/UIState";
import { GLOBAL_ELEMENT_IDS } from "@core/ui/global-element-ids";
import { useWire } from "@core/wire";
import styles from "./SideBarAccordion.module.css";
import { CollectionsContextMenu } from "./context-menu/CollectionsContextMenu";
interface Props extends ParentProps {
  label: string;
  collectionId: string;
  depth: number;
  // count: number;
  initiallyOpen?: boolean;
  icon?: StIconComponent;
  class?: string;
  hasChildren: boolean;
  selected?: boolean;
  root: boolean;
  onMainContentClick?: () => void;
  onCollectionCreated: (label: string) => void;
  filter?: string;
}

export const SideBarAccordion = (props: Props) => {
  const wire = useWire();
  const state = useUIState();
  const uniqueId = createUniqueId();
  const accordionId = `accordion-${props.label.replace(/\s/g, "-").toLowerCase()}-${uniqueId}`;
  const accordionMenuId = `accordion-menu-${props.label.replace(/\s/g, "-").toLowerCase()}-${uniqueId}`;
  const [menuToggle] = state.collectionsContextMenu;
  const [toggle, setToggle] = createSignal(props.initiallyOpen ?? false);
  const [configRef, setConfigRef] = createSignal<HTMLElement>((<></>) as HTMLElement);
  const collection = () => wire.services.collections.getCollection(props.collectionId);
  const isGuest = wire.services.auth.isGuest;

  const showMenu = () => {
    if (collection()?.collectionKind === CollectionKind.Org && isGuest()) return false;
    return true;
  };

  //  Aligns to icons
  // const indent = () => {
  //   if (props.hasChildren) {
  //     if (props.depth === 0) return "0px";
  //     return `padding-left: ${props.depth * 36}px;`; // width-diff Account for centering of icon
  //   }
  //   if (props.depth === 1) return "padding-left: 44px;";
  //   return `padding-left: calc(${props.depth * 36}px - 26px);`; // calc(depth * 36 - (width * 0.5 + (width-diff * 0.5))
  // };

  const indent = () => {
    if (props.hasChildren) {
      if (props.depth === 0) return "0px";
      return `padding-left: calc(${props.depth * 18}px);`; // width-diff Account for centering of icon
    }
    if (props.depth === 1) return "padding-left: 44px;";

    return `padding-left: calc(${props.depth * 18}px + 26px);`; // calc(depth * 36 - (width * 0.5 + (width-diff * 0.5))
  };

  const isFiltered = () => {
    if (props.filter) {
      return props.label.toLowerCase().replace(/\s/g, "").includes(props.filter.toLowerCase().replace(/\s/g, ""));
    }
    return true;
  };

  return (
    <>
      <div
        data-filtered={isFiltered()}
        class={classNames(
          styles["sidebar-accordion"],
          props.selected ? styles["sidebar-accordion--selected"] : "",
          props.root ? styles["sidebar-accordion--root"] : "",
          !props.hasChildren ? styles["sidebar-accordion--empty"] : "",
        )}
        // style={indent()}
      >
        {/* Expand/Contract */}
        <Switch>
          <Match when={props.hasChildren || showMenu() === false}>
            <button
              type="button"
              on:click={(event: Event) => {
                setToggle(!toggle());
                stAnalytics.track("click_tracking", {
                  cta: "expand_collection_state",
                  position: "left_drawer",
                  screen: getScreenType(),
                });
                event.preventDefault();
                event.stopImmediatePropagation();
              }}
              aria-controls={accordionId}
              aria-expanded={toggle()}
              class={styles["sidebar-accordion__trigger"]}
              tabIndex={0}
              disabled={showMenu() === false}
            >
              <span class={styles["sidebar-accordion__indicator-container"]}>
                <span
                  class={classNames(
                    styles["sidebar-accordion__indicator"],
                    toggle() && showMenu() === true ? styles["sidebar-accordion__indicator--open"] : undefined,
                  )}
                />
              </span>
              <span class="screen-reader">{`${toggle() ? "Collapse" : "Expand"} the ${props.label} list.`}</span>
            </button>
            {/* Load Content */}
            {/* ANDI - Noting main content ID usage here */}
            <button
              type="button"
              aria-controls={GLOBAL_ELEMENT_IDS.mainContent}
              data-component="sidebar-accordion-trigger"
              data-collection-id={props.collectionId}
              class={styles["sidebar-accordion__action"]}
              tabIndex={0}
              on:click={props.onMainContentClick}
            >
              <Show when={props.icon}>
                <StIcon icon={props.icon!} class={styles["sidebar-accordion__icon"]} size="1.5rem" />
              </Show>
              <span class="sr-only">Open the</span>
              <span class={classNames(styles["sidebar-accordion__label"])}>{props.label}</span>
              <span class="sr-only">Collection.</span>
              {/* <ChatCount class={styles.sidebarAccordionCount} count={props.count} /> */}
            </button>
          </Match>
          <Match when={!props.hasChildren && props.root}>
            <button
              type="button"
              data-component="sidebar-accordion-trigger"
              aria-controls={accordionId}
              aria-expanded={false}
              class={styles["sidebar-accordion__trigger"]}
              tabIndex={0}
              disabled={true}
            >
              <span class={styles["sidebar-accordion__indicator-container"]}>
                <span class={styles["sidebar-accordion__indicator"]} />
              </span>
              <span class="screen-reader">{`${toggle() ? "Collapse" : "Expand"} the ${props.label} list.`}</span>
            </button>
            <button
              type="button"
              aria-controls={GLOBAL_ELEMENT_IDS.mainContent}
              class={styles["sidebar-accordion__action"]}
              data-component="sidebar-accordion-trigger"
              data-collection-id={props.collectionId}
              tabIndex={0}
              on:click={props.onMainContentClick}
            >
              <Show when={props.icon}>
                <StIcon icon={props.icon!} class={styles["sidebar-accordion__icon"]} size="1.5rem" />
              </Show>
              <span class="sr-only">Open the</span>
              <span class={classNames(styles["sidebar-accordion__label"])}>{props.label}</span>
              <span class="sr-only">Collection.</span>
              {/* <ChatCount class={styles.sidebarAccordionCount} count={props.count} /> */}
            </button>
          </Match>
          <Match when={!props.hasChildren}>
            {/* Load Content */}
            <button
              type="button"
              aria-controls={GLOBAL_ELEMENT_IDS.mainContent}
              class={styles["sidebar-accordion__action"]}
              data-component="sidebar-accordion-trigger"
              data-collection-id={props.collectionId}
              tabIndex={0}
              on:click={props.onMainContentClick}
            >
              <Show when={props.icon}>
                <StIcon icon={props.icon!} class={styles["sidebar-accordion__icon"]} size="1.5rem" />
              </Show>
              <span class="sr-only">Open the</span>
              <span class={classNames(styles["sidebar-accordion__label"])}>{props.label}</span>
              <span class="sr-only">Collection.</span>
              {/* <ChatCount class={styles.sidebarAccordionCount} count={props.count} /> */}
            </button>
          </Match>
        </Switch>

        {/* Create collection -- retain for further changes */}
        {/* <button
          type="button"
          aria-controls={GLOBAL_ELEMENT_IDS.modal}
          aria-expanded={modalOpen() === "create-collection-modal"}
          class={classNames(styles["sidebar-accordion__menu"])}
          tabIndex={0}
          ref={(ref) => setConfigRef(ref)}
          on:click={(event: Event) => {
            setModalContents(() => () => (
              <CreateCollectionModal
                id="create-collection-modal"
                parentLabel={props.label || ""}
                onCreate={async (name, description) => {
                  const res = await wire.services.collections.apiCreateCollection(
                    wire.services.collections.getDataOrFail(props.collectionId || ""),
                    name,
                    description,
                  );
                  const id = res.data?.id;
                  if (id) {
                    navigate(urls.collection(id));
                  }
                  setModalOpen("");
                  setModalContents(null);
                }}
              />
            ));
            stAnalytics.track("click_tracking", {
              cta: "create_collection_plus",
              position: "left_drawer",
              screen: getScreenType(),
            });
            setModalOpen("create-collection-modal");
            event.preventDefault();
            event.stopImmediatePropagation();
          }}
        >
          <span class="sr-only">Open a dialog to create a new Collection.</span>
          <StIcon icon={OcPluscircle2} class={styles["sidebar-accordion__menu-icon"]} size="1.25rem" />
        </button> */}
        {/* Context menu */}
        <Show when={showMenu()}>
          <button
            type="button"
            aria-controls={accordionMenuId}
            aria-expanded={menuToggle() === accordionMenuId}
            aria-haspopup="true"
            tabIndex={0}
            ref={(ref) => setConfigRef(ref)}
            class={classNames(
              styles["sidebar-accordion__menu"],
              menuToggle() === accordionMenuId ? styles["sidebar-accordion__menu--visible"] : "",
            )}
          >
            <span class="sr-only">{`Open ${props.label} action menu.`}</span>
            <StIcon icon={TbDotsVertical} class={styles["sidebar-accordion__menu-icon"]} size="1.25rem" />
          </button>
          <CollectionsContextMenu
            collectionId={props.collectionId}
            bound={configRef()}
            id={accordionMenuId}
            placement="bottom-start"
            onCollectionCreated={props.onCollectionCreated}
          />
        </Show>
      </div>
      <Show when={props.hasChildren}>
        <div role="region" id={accordionId} class={styles["sidebar-accordion__content"]}>
          <Show when={toggle()}>
            <menu class={styles["sidebar-accordion__nest"]}>
              <div class={styles["sidebar-accordion__nest-padded"]}>{props.children}</div>
            </menu>
          </Show>
        </div>
      </Show>
    </>
  );
};
