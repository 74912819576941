import { type Accessor, onMount, onCleanup, createEffect } from "solid-js";
import { createStore } from "solid-js/store";
import { isServer } from "solid-js/web";

const getElementSize = (target: HTMLElement | undefined) => {
  if (isServer || !target) {
    return { width: 0, height: 0, top: 0, left: 0, right: 0, bottom: 0 };
  }
  const { width, height, top, left, right, bottom } = target.getBoundingClientRect();
  return { width, height, top, left, right, bottom };
};

/**
 * Use this instead of createElementSize from @solid-primitives/resize-observer
 * when you need to observe the top, right, bottom, and left as well,
 * not just the width and height.
 */
export const createElementSizeObserver = (target: Accessor<HTMLElement | undefined>) => {
  const [size, setSize] = createStore(getElementSize(undefined));
  onMount(() => {
    const ro = new ResizeObserver(([e]) => setSize(getElementSize(e?.target as HTMLElement)));
    onCleanup(() => ro.disconnect());

    createEffect(() => {
      const el = target();
      if (el) {
        setSize(getElementSize(el));
        ro.observe(el);
        onCleanup(() => ro.unobserve(el));
      }
    });
  });

  return size;
};
