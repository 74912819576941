/**
 * Creates a resource options object.
 */
export const createListResourceOptions = (page: number, sort?: string[], limit?: number): ListResourceOptions => {
  const defaultLimit = 50;
  return {
    Limit: limit ?? defaultLimit,
    Offset: limit ? (page - 1) * limit : (page - 1) * defaultLimit,
    Sort: sort ?? ["modifiedAt"],
  };
};
