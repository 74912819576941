import { BsPersonFillGear } from "solid-icons/bs";
import { IoPerson } from "solid-icons/io";
import { Match, Show, Switch } from "solid-js";
import { StIcon } from "@core/components/icons";
import { classNames } from "@core/lib/classNames";
import { assignAvatarColor } from "@core/lib/ui/assignAvatarColor";
import styles from "./UserAvatar.module.css";
export interface AvatarProps {
  initial?: string;
  imageUrl?: string;
  isGuest?: boolean;
  isMenu?: boolean;
  isAccount?: boolean;
  name: string;
  size: "share" | "menu" | "thread" | "inline";
}
export type UserAvatarProps = Exclusive<AvatarProps, "initial" | "imageUrl" | "isGuest">;

/**
 * **UserAvatar**
 *
 * Note: Requires either `initial` **or** `imageUrl` to be provided.
 */
export const UserAvatar = (props: UserAvatarProps) => {
  return (
    <span
      class={classNames(
        styles["user-avatar"],
        !props.isGuest ? assignAvatarColor(props.name) : "",
        props.size ? styles[`user-avatar--${props.size}`] : "",
        props.isGuest ? styles["user-avatar--guest"] : "",
        props.isAccount ? styles["user-avatar--account"] : "",
      )}
      title={props.name}
    >
      <Switch>
        <Match when={props.imageUrl}>
          <img src={props.imageUrl} alt={`${props.name}'s avatar image.`} />
        </Match>
        <Match when={props.initial}>
          <span class={styles["user-avatar__text"]}>{props.initial}</span>
        </Match>
        <Match when={props.isGuest}>
          <Show when={props.isMenu}>
            <StIcon
              icon={BsPersonFillGear}
              class={styles["user-avatar__icon"]}
              size={
                props.size === "inline"
                  ? "1rem"
                  : props.size === "share"
                    ? "0.8rem"
                    : props.size === "menu"
                      ? "1.375rem"
                      : "2rem"
              }
            />
          </Show>
          <Show when={!props.isMenu}>
            <StIcon
              icon={IoPerson}
              class={styles["user-avatar__icon"]}
              size={
                props.size === "inline"
                  ? "1rem"
                  : props.size === "share"
                    ? "0.8rem"
                    : props.size === "menu"
                      ? "1.375rem"
                      : "2rem"
              }
            />
          </Show>
        </Match>
      </Switch>
    </span>
  );
};
