import { stAnalytics } from "@repo/analytics";
import { BrowserStorage } from "@repo/storage";
import { useNavigate } from "@solidjs/router";
import { BsPersonFillAdd } from "solid-icons/bs";
import { TbLogin, TbLogout2 } from "solid-icons/tb";
import { useUIState } from "@core/ui/UIState";
import { useWire } from "@core/wire";
import { ContextMenu, type ContextMenuProps } from "./ContextMenu";

type Props = Omit<ContextMenuProps, "items" | "signal">;

export const UserContextMenu = (props: Props) => {
  const wire = useWire();
  const navigate = useNavigate();
  const signal = useUIState().accountContextMenu;

  const onSignOut = async () => {
    await wire.services.auth.methods.signOut();
    navigate("/");
  };

  const items = () => {
    if (wire.services.auth.isGuest()) {
      return [
        {
          label: "Log in",
          icon: TbLogin,
          onClick: () => {
            stAnalytics.track("log_in_clicked", undefined);
            BrowserStorage.setSignupReferrerUrl(location.pathname);
          },
        },
        {
          label: "Sign up",
          icon: BsPersonFillAdd,
          onClick: () => {
            stAnalytics.track("sign_up_clicked", undefined);
            BrowserStorage.setSignupReferrerUrl(location.pathname);
          },
        },
      ];
    }
    return [
      {
        label: "Logout",
        icon: TbLogout2,
        onClick: onSignOut,
      },
    ];
  };
  return <ContextMenu bound={props.bound} id={props.id} items={items()} signal={signal} placement="bottom-end" />;
};
