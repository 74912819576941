import { TbMessage } from "solid-icons/tb";
import { Show } from "solid-js";
import { classNames } from "@core/lib/classNames";
import styles from "./ListedChat.module.css";
import { formatLastModifiedRelative } from "@core/lib/formatting/formatLastModifiedRelative";

interface Props {
  label: string;
  collection?: string;
  selected?: boolean;
  onClick?: () => void;
  time: string;
}

export const ListedChat = (props: Props) => {
  return (
    <button
      type="button"
      on:click={props.onClick}
      class={classNames(styles["listed-chat"], props.selected ? styles["listed-chat--selected"] : "")}
      tabIndex={0}
    >
      <TbMessage class={styles["listed-chat__icon"]} size={"1.5rem"} />
      <span class={styles["listed-chat__content"]}>
        <span class={styles["listed-chat__label"]}>{props.label}</span>

        <span class="flex items-baseline gap-3 justify-between">
          <Show when={props.collection} fallback={<span aria-hidden />}>
            <span class={styles["listed-chat__sub"]}>{props.collection}</span>
          </Show>

          <span class="type-body-xs font-normal text-on-background-alternate truncate">
            {formatLastModifiedRelative(props.time)}
          </span>
        </span>
      </span>
    </button>
  );
};
