import type { IconTypes } from "solid-icons";
import { BsPersonFillLock } from "solid-icons/bs";
import { OcGlobe2 } from "solid-icons/oc";
import { TbStar } from "solid-icons/tb";
import { CollectionKind } from "./collections.types";

export enum VisibleCollections {
  All = 0,
  BothSharedAndPersonal = 1,
  OnlyPersonal = 2,
}
