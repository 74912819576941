import { TbSearch } from "solid-icons/tb";
import { Accessor, createSignal, type JSX } from "solid-js";
import styles from "./LargeSearchBar.module.css";
import { twMerge } from "tailwind-merge";

interface Props {
  placeholder: string;
  label: string;
  inputProps?: JSX.InputHTMLAttributes<HTMLInputElement>;
  value?: string;
  setValue?: (value: string) => void;
}

export const LargeSearchBar = (props: Props) => {
  return (
    <div class={styles["large-search-bar"]}>
      <TbSearch class={styles["large-search-bar__icon"]} size="1.75rem" />
      <input
        type="search"
        placeholder={props.placeholder}
        aria-label={props.label}
        value={props.value}
        onInput={(event) => props.setValue?.(event.currentTarget.value)}
        {...props.inputProps}
        class={twMerge(styles["large-search-bar__field"], props.inputProps?.class)}
      />
    </div>
  );
};
