import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";

// Initialize the relative time plugin
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

export const formatLastModifiedRelative = (timestamp: string) => {
  const now = dayjs();
  const modified = dayjs(timestamp);
  const hoursDiff = now.diff(modified, "hour");

  // If less than 24 hours ago, use relative time
  if (hoursDiff < 24) {
    return modified.fromNow(); //  "5 minutes ago", "2 hours ago"
  }

  if (hoursDiff < 48) {
    return "Yesterday";
  }

  if (hoursDiff < 72) {
    return "2 days ago";
  }

  return modified.format("LL");
};
