import { captureException } from "@repo/observability";
import { TbUserPlus } from "solid-icons/tb";
import { createSignal, type Component } from "solid-js";
import type { CollectionAccessRecord } from "@core/domains/collections/collections.types";
import { useWire } from "@core/wire";
import { PrimaryCTA } from "../cta/PrimaryCTA";
import type { EditUserPermissionsOptions } from "../EditUserPermissions";
import type { UserAvatarProps } from "../UserAvatar";
import { LargeInputText } from "./LargeInputText";
import styles from "./UsersTagsList.module.css";
import { ValidationMethods } from "@repo/validation";

export type UsersTagListTag = RequireAtLeastOne<
  { id: string; avatar?: UserAvatarProps; email?: true },
  "avatar" | "email"
>;

export const UsersTagsList: Component<{
  sendInvites: (tags: UsersTagListTag[]) => Promise<void>;
  options: EditUserPermissionsOptions;
  access: CollectionAccessRecord[] | undefined;
}> = (props) => {
  const wire = useWire();

  const [error, setError] = createSignal<string | undefined>(undefined);

  const [inputValue, setInputValue] = createSignal("");
  const [isSending, setIsSending] = createSignal(false);

  const handleSubmit = async (event: SubmitEvent) => {
    event.stopImmediatePropagation();
    event.preventDefault();

    if (event.target instanceof HTMLFormElement) {
      if (isSending()) {
        return;
      }

      setIsSending(true);

      const email = inputValue().trim();

      if (!email) {
        setError("Please enter an email address");
        setIsSending(false);
        return;
      }

      if (!ValidationMethods.email(email)) {
        setError("Please enter a valid email address");
        setIsSending(false);
        return;
      }

      if (email === wire.services.auth.user()?.email) {
        setError("You cannot invite yourself");
        setIsSending(false);
        return;
      }

      if (props.access?.some((a) => a.email === email)) {
        setError("You cannot invite the same person twice");
        setIsSending(false);
        return;
      }

      try {
        await props.sendInvites([
          {
            id: email,
            email: true,
          },
        ]);
      } catch (error) {
        setError("Something went wrong. Please try again.");
        captureException(error);
      }

      setIsSending(false);
      setError(undefined);
      setInputValue("");
    }
  };

  return (
    <div class={styles["tags-list"]}>
      <form class={styles["tags-list__form"]} onSubmit={handleSubmit}>
        <LargeInputText
          placeholder="Enter an email address"
          label="Enter an email address"
          name="email"
          modifier="outline"
          value={inputValue()}
          onInput={(e) => {
            setInputValue((e.currentTarget as HTMLInputElement).value);
            setError(undefined);
          }}
        />
        <PrimaryCTA
          type="submit"
          class={styles["tags-list__form-submit"]}
          data-test-id="invite-person"
          accessiblePrefix="Click to "
          icon={TbUserPlus}
          label="Invite"
          loading={isSending()}
        />
      </form>

      <p class="type-body-sm text-on-surface-danger">{error() || " "}</p>
    </div>
  );
};
