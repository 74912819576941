import { stAnalytics } from "@repo/analytics";
import { useNavigate } from "@solidjs/router";
import { type Component, For } from "solid-js";
import { SideBarAccordion } from "@core/components/SideBarAccordion";
import { getRootCollectionUIOverloads } from "@core/domains/collections/collections.helpers";
import { getScreenType } from "@core/lib/ui/getScreenType";
import { urls } from "@core/lib/urls";
import { useUIState } from "@core/ui/UIState";
import { useWire } from "@core/wire";

export const SidebarCollection: Component<{
  collectionId: string;
  depth: number;
  refreshTree: () => void;
  root?: boolean;
  onCollectionCreated: (label: string) => void;
  /**
   * Callback for when a collection is clicked.
   * The default behavior is to navigate to the collection url.
   * Passing a callback will override the default behavior.
   */
  onCollectionClick?: (collectionId: string) => void;
  filter?: string;
}> = (props) => {
  const wire = useWire();
  const navigate = useNavigate();
  const ui = useUIState();
  const [collectionId] = ui.collectionId;
  const [, setGettingStartedScreenManuallyHidden] = ui.gettingStartedScreenManuallyHidden;
  const url = () => urls.collection(props.collectionId);

  const collection = () => wire.services.collections.getCollection(props.collectionId);
  const rootOverride = () => getRootCollectionUIOverloads(collection());
  const children = () =>
    wire.services.collections
      .getChildren(props.collectionId)
      .sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: "base", ignorePunctuation: true }));

  return (
    <SideBarAccordion
      collectionId={props.collectionId}
      filter={props.filter}
      root={!!props.root}
      label={rootOverride()?.label || collection()?.label || ""}
      hasChildren={children().length !== 0}
      depth={props.depth}
      icon={rootOverride()?.icon}
      initiallyOpen
      selected={collectionId() === props.collectionId}
      onMainContentClick={() => {
        setGettingStartedScreenManuallyHidden(true);
        stAnalytics.track("click_tracking", {
          cta: "navigate_to_collection",
          position: "left_drawer",
          screen: getScreenType(),
        });
        if (props.onCollectionClick) {
          props.onCollectionClick(props.collectionId);
        } else {
          navigate(url());
        }
      }}
      onCollectionCreated={props.onCollectionCreated}
    >
      <For each={children()}>
        {(child) => (
          <SidebarCollection
            filter={props.filter}
            onCollectionClick={props.onCollectionClick}
            onCollectionCreated={props.onCollectionCreated}
            collectionId={child.id}
            depth={props.depth + 1}
            refreshTree={props.refreshTree}
          />
        )}
      </For>
    </SideBarAccordion>
  );
};
