import type { IconTypes } from "solid-icons";
import { For, Show, type ParentProps } from "solid-js";
import { StIcon } from "@core/components/icons";
import { classNames } from "@core/lib/classNames";
import styles from "./InfoPanel.module.css";

interface Props extends ParentProps {
  title?: string;
  messages?: string[];
  subtext?: string[];
  icon: IconTypes;
  variant: "info" | "danger" | "warning";
}

export const InfoPanel = (props: Props) => {
  return (
    <div class={classNames(styles["info-panel"], props.variant ? styles[`info-panel--${props.variant}`] : "")}>
      <div class={styles["info-panel__left"]}>
        <StIcon icon={props.icon} class={styles["info-panel__icon"]} size="1.5rem" />
      </div>
      <div class={styles["info-panel__right"]}>
        <div class={styles["info-panel__right-content"]}>
          {props.title && <p class={styles["info-panel__title"]}>{props.title}</p>}
          <Show when={props.messages}>
            <p class={styles["info-panel__messages"]}>
              <For each={props.messages}>
                {(message) => <span class={styles["info-panel__message"]}>{message}</span>}
              </For>
            </p>
          </Show>
          <Show when={props.subtext}>
            <p class={styles["info-panel__subtext"]}>
              <For each={props.subtext}>
                {(message) => <span class={styles["info-panel__message"]}>{message}</span>}
              </For>
            </p>
          </Show>
          <Show when={props.children}>
            <div class={styles["info-panel__footer"]}>{props.children}</div>
          </Show>
        </div>
      </div>
    </div>
  );
};
