import { BiRegularErrorAlt } from "solid-icons/bi";
import { TbMessageBolt } from "solid-icons/tb";
import { PrimaryCTA } from "@core/components/cta/PrimaryCTA";
import { InfoPanel } from "@core/components/InfoPanel";
import { useUIState } from "@core/ui/UIState";
import { useWire } from "@core/wire";
import styles from "./ErrorModal.module.css";
interface Props {
  title: string;
  errorMessage: string;
}
export const ErrorModal = (props: Props) => {
  const wire = useWire();
  const state = useUIState();
  const [, setModalOpen] = state.modal;
  const [, setModalContents] = state.modalContents;
  return (
    <div class={styles["error-modal"]}>
      <p class={styles["error-modal__title"]}>
        <span class={styles["error-modal__title-text"]}>Something went wrong.</span>
      </p>
      <InfoPanel
        title={props.title}
        messages={[
          "Our engineering team have been notified of the issue and will be investigating.",
          "By submitting feedback you can help us resolve your issue faster.",
        ]}
        subtext={["Your error was:", props.errorMessage]}
        icon={BiRegularErrorAlt}
        variant="danger"
      />
      <div class={styles["error-modal__buttons"]}>
        <div aria-hidden class={styles["error-modal__buttons-left"]} />
        <div class={styles["error-modal__buttons-right"]}>
          <PrimaryCTA
            data-test-id="error-modal-submit-feedback"
            accessibleSuffix=""
            label="Submit Feedback"
            type="submit"
            icon={TbMessageBolt}
            onClick={() => {
              wire.services.feedback.openFeedbackPanel();
              setModalOpen("");
              setModalContents(null);
            }}
          />
        </div>
      </div>
    </div>
  );
};
