import { DesktopOnly } from "@core/components/containers/DesktopOnly";
import { SidebarChats } from "@core/components/SidebarChats";
import { SidebarCollections } from "@core/components/SidebarCollections";
import { StorytellLogo } from "@core/components/StorytellLogo";
import styles from "./AlternativeSideBar.module.css";
import { classNames } from "@core/lib/classNames";
import { cookieStorage, makePersisted } from "@solid-primitives/storage";
import { createSignal } from "solid-js";
import Resizable from "corvu/resizable";
interface Props {
  collectionId: string | undefined;
}

export const AlternativeSideBar = (props: Props) => {
  const [sizes, setSizes] = makePersisted<number[]>(createSignal([0.2, 0.8]), {
    name: "st_lefbar_size:v1",
    storage: cookieStorage,
    storageOptions: { expires: new Date(+new Date() + 3.1536e12) },
  });

  return (
    <>
      <DesktopOnly>
        <StorytellLogo modifiers={["spacing"]} />
      </DesktopOnly>
      <div class={classNames(styles["alternative-sidebar"], "mt-2")} style={{ height: "calc(100vh - 5.4rem)" }}>
        <Resizable orientation="vertical" class="size-full flex-nowrap" sizes={sizes()} onSizesChange={setSizes}>
          <Resizable.Panel initialSize={0.2} minSize={0.1} class="size-full overflow-hidden">
            <div class="size-full overflow-y-auto overflow-x-hidden px-4">
              <SidebarChats />
            </div>
          </Resizable.Panel>
          <Resizable.Handle class="group h-10 flex flex-col items-center justify-center w-full min-h-0 mt-2">
            <span class="h-0.5 bg-background-decoration w-full block group-data-[active]:h-1 group-data-[active]:bg-primary transition-colors" />
          </Resizable.Handle>
          <Resizable.Panel initialSize={0.8} minSize={0.3} class="size-full overflow-hidden">
            <div data-component="sidebar-collections" class="size-full overflow-y-auto overflow-x-hidden px-4">
              <SidebarCollections />
            </div>
          </Resizable.Panel>
        </Resizable>
      </div>
    </>
  );
};
